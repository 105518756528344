import React from "react"
import { Link } from "gatsby"
import { motion } from 'framer-motion'
import {StaticImage} from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import TestimonialSection from "../components/testimonials-section"
import ClientLogos from "../components/client-logos"
import Cta from "../components/cta"
import ArrowRight from "../components/svg/arrowRight"

const IndexPage = () => {

  return (

    <Layout>
      <Seo 
        title="Startups, web developers and web design - Sydney Canberra" 
        description="Launch Lab are web developers and web designers in Sydney & Canberra. We use Python and React.js to develop websites & apps for startups and companies."
      />
      <section role="main">
        <div className="container">
          <div className="row flex flex-jc">
            <div className="hero-content">
              <h1
                className="small-h"
              >Web design &amp; development</h1>
              <motion.h2 
                className="hero-lead"
                initial="hidden"
                animate={{ opacity: [0, 0.25, 0.5, 0.75, 1], y: [80, 0] }} 
                transition={{ ease: "easeOut", duration: 0.5 }}
              >
                We're one of the most experienced <Link to="/startup-web-developers/" className="underline-magic">startup developers</Link> in Australia. We're trusted by large organisations too.
              </motion.h2>
              <div className="btn-row">
                <motion.div 
                  initial="hidden"
                  animate={{ opacity: [0, 0.25, 0.5, 0.75, 1], x: [-80, 0] }} 
                  transition={{ ease: "easeOut", duration: 0.75 }}
                >
                  <Link 
                    to="/contact/" className="btn btn-lg btn-purple"
                  >
                    <span className="flex flex-ac">
                      Start your project
                      <ArrowRight />
                    </span>
                  </Link>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-purple with-pad">
        <div className="container-lg">
          <div className="row row-mob-no-pad">
            <motion.div 
              className="work-jumbo-wrapper with-radius"
              initial="hidden"
              animate={{ opacity: [0, 0.25, 0.5, 0.75, 1], y: [120, 0], scale: [0.9, 1] }} 
              transition={{ ease: "easeOut", delay: 0.5, duration: 1 }}
            >
                  <StaticImage 
                      src="../images/launch-lab-work-2024.png"
                      alt="Screengrabs of some of the websites and applications that Launch Lab have designed and developed"
                      formats={["AUTO", "WEBP", "AVIF"]}
                      placeholder="none"
                      backgroundcolor="white"
                      layout="fullWidth"
                      quality="100%"
                      className="with-radius"
                  />
            </motion.div>
            <div className="flex flex-jc">
              <Link to="/portfolio/" className="btn btn-lg btn-pink">
                <span className="flex flex-ac">
                  View all work
                  <ArrowRight />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="section-pad">
        <div className="container">
          <div className="row">
            <motion.div 
              initial={{ opacity: 0, y: 80 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ ease: "easeOut", duration: 0.5 }}
            >
              <h3 className="small-h">Web development services</h3>
              <h4>We're <Link to="/services/" className="underline-magic">onshore web developers</Link> offering web design, web development and startup services</h4>
            </motion.div>
            <motion.div 
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ ease: "easeOut", delay: 0.25, duration: 0.75 }}
            >
              <div className="services-wrapper top-row">
                <div className="a-service">
                  <h5>
                    <Link to="/web-design/" className="btn-link">Web Design <ArrowRight /></Link>
                  </h5>
                  <p>We design user and search engine friendly websites and product UIs</p>
                </div>
                <div className="a-service">
                  <h5>
                    <Link to="/web-development/" className="btn-link">Web Development <ArrowRight /></Link>
                  </h5>
                  <p>We develop websites, content management systems and software</p>
                </div>
                <div className="a-service">
                  <h5>
                    <Link to="/startup-web-developers/" className="btn-link">Startups <ArrowRight /></Link>
                  </h5>
                  <p>We help startup founders go from idea to end product</p>
                </div>
              </div>
              <div className="services-wrapper top-row">
                <div className="a-service">
                    <h4>
                      <Link to="/startup-web-designers/" className="btn-link">Startup Web Design <ArrowRight /></Link>
                    </h4>
                    <p>We design startup websites and applications for early stage and established startups.</p>
                </div>
                <div className="a-service">
                  <h5>
                    <Link to="/webflow-developer/" className="btn-link">Webflow Developers <ArrowRight /></Link>
                  </h5>
                  <p>We build Webflow websites for startups and businesses. We also have Webflow Enterprise experience.</p>
                </div>
                <div className="a-service">
                  <h5>
                    <Link to="/machine-learning-developers-sydney/" className="btn-link">Machine Learning <ArrowRight /></Link>
                  </h5>
                  <p>We use Python, Tensorflow, ML and data science to help data rich companies solve problems</p>
                </div>
              </div>
              <div className="services-wrapper">
                <div className="a-service">
                  <h5>
                    <Link to="/artificial-intelligence-developers/" className="btn-link">Artificial Intelligence <ArrowRight /></Link>
                  </h5>
                  <p>We leverage large language models (LLMs) like ChatGPT and Mistral, via AWS Bedrock, to automate tasks within applications.</p>
                </div>
                <div className="a-service">
                  <h5>
                    <Link to="/aws-infrastructure/" className="btn-link">AWS <ArrowRight /></Link>
                  </h5>
                  <p>We assist startups with Amazon Web Services (AWS) serverless infrastructure setup and support.</p>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      <div className="container">
          <div className="row">
            <div className="section-line"></div>
          </div>
      </div>

      <section className="section-pad">
        <div className="container">
          <div className="row">
            <div className="cols">
              <div className="col-26"
              >
                <h5>Partners</h5>
                <p>From developing fintech, proptech and healthtech applications to designing marketing websites and machine learning projects, we've worked with early stage and funded startups, large organisations and government.</p>
                <div className="mt-1 mb-2">
                  <motion.div 
                    initial="hidden"
                    animate={{ opacity: [0, 0.25, 0.5, 0.75, 1], x: [-80, 0] }} 
                    transition={{ ease: "easeOut", duration: 0.75 }}
                  >
                    <Link 
                      to="/contact/" className="btn btn-lg btn-purple"
                    >
                      <span className="flex flex-ac">
                        About Us
                        <ArrowRight />
                      </span>
                    </Link>
                  </motion.div>
                </div>
              </div>
              <motion.div 
                className="col-67 flex flex-sb flex-wrap"
                initial={{ opacity: 0, y: 100 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ ease: "easeOut", delay: 0.25, duration: 0.75 }}
              >
                <ClientLogos />
              </motion.div>
            </div>
          </div>
        </div>
      </section>

      <TestimonialSection />

      <Cta
        text="If you're looking for a web design quote or a web development quote from a local, onshore, and highly experienced, Australian web design and development team, we'd love to discuss how we can help and collaborate on your project."  
      />

    </Layout>
  );
};

export default IndexPage;